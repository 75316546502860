import React, { useEffect, useState } from 'react'
import iconWifi from "../Images/icons/icons8-wifi-100.png"
import iconStorage from "../Images/icons/icon-storage.png"
import iconFile from "../Images/icons/icon-file.png"
import iconHome from "../Images/icons/icon-home.png"
import iconFolder from "../Images/icons/icons-folder.png"
import { useDispatch, useSelector } from 'react-redux'
import { ethers } from 'ethers'
import SamllCard from './Cards/SamllCard'
import NetworkCard from './Cards/NetworkCard'
import CardTable from './Cards/CardTable'
import tableUpload from '../Images/icons/icon-Table-upload.png'
import PieChartCard from './Cards/PieChartCard'
import iconStatus from "../Images/icons/icon-status.png"
import { useNavigate } from 'react-router-dom'
import requireAvalibilitySlice from '../reducers/RequireAvalibilySlice'
import metamaskLogo from "../Images/icons/metamask.png"
import { a } from '@react-spring/three'


function DashboardHome() {
  const navigate = useNavigate()
  const [dashboardDetails, setDashboardDetails] = useState(null)
  const fileStorageAddress = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS;  // contract address
  const fileStorageJSON = require("../json/FileStorage.json");

  const dispatch = useDispatch()

  const fileStorageABI = fileStorageJSON.abi;
  const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)

  const [usedSpace , setUsedSpace] = useState(null) // used space state

  // get user details 
  const userDid = useSelector((state) => state.DidReducer)

  // current package currentPackage
  const [currentPackage, setCurrentPackage] = useState("")

  // if usre is here but not metamask here 
  const [noMetamask, setNoMetamask] = useState(false)

  const getFileDetails = async () => {

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const address = await signer.getAddress()

      const contract = new ethers.Contract(fileStorageAddress, fileStorageABI, signer)

      const fileStorageJSON2 = require("../json/FileStorage2.json");
      const fileStorageAddress2 = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS_2
      const fileStorageABI2 = fileStorageJSON2.abi

      const contract2 = new ethers.Contract(fileStorageAddress2, fileStorageABI2, signer)
    
      // get storage details 
      getStorageDetails(contract2,address)

      const res = await contract.getTotalFilesCount()
      setDashboardDetails(res)
    } catch (error) {
      console.log("This is error :", error)
      // navigate("/")
    }
  }

  // get dashboard storage details
  const getStorageDetails = async (contract,address) => {
    try {
      const current_package = await contract.getCurrentPackage(userDid,address)
      setCurrentPackage(current_package._packageName)
      
      const used_space = await contract.getUsedSpace(userDid)
      setUsedSpace(parseInt(used_space._hex))
    } catch (error) {
      console.log(error)
    }

  }

  // file details 
  const [fileNames, setFileNames] = useState({})


  useEffect(() => {
    userVerification()

    if (!window.ethereum) {
      dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: false, useruserAdded: false, noUser: false, allreadyRegisteredWallet: false }))
      return
    }
    getFileDetails();

  }, [])

  // is user verifeid or not 
  const userVerification = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + "wallets", {
        method: "POST",
        body: JSON.stringify({
          "DID": userDid
        })
      })
      const userVerificationResults = await res.json()

      if ((userVerificationResults && !window.ethereum)) {
        setNoMetamask(true)
      }

    } catch (e) {
      console.log(e)
    }
  }


  return (
    <div className='w-full '>
      <div className={`w-full ${noMetamask ? "pointer-events-none grayscale" : ""}  text-white animate__animated animate__fadeIn ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none"}`}>
        {/* topic container */}
        <div className='flex mt-3 mb-5'>
          <div className=''>
            <img src={iconHome} alt="" className='w-[20px] inline-block -mt-1' />
          </div>

          <div className='ml-2'>
            <h3>DASHBOARD</h3>
          </div>
        </div>


        {/* main container div */}
        <div className='flex w-full h-full border-white pl-2 pr-3 
      lg:flex-nowrap md:flex-wrap sm:flex-wrap min-[320px]:flex-wrap'>

          {/* three card compoents and network stats and table div*/}
          <div className={`flex flex-col p-2 gap-5 lg:w-2/3 md:w-full sm:w-full min-[320px]:w-full ${!avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser && !avalibilityData.allreadyRegisteredWallet ? "" : "grayscale blur-sm"}`}>

            {/* three cards div */}
            <div className='flex w-full gap-5 lg:flex-nowrap md:flex-wrap sm:flex-wrap min-[320px]:flex-wrap'>
              <SamllCard card_icon={iconFolder} heading={'Folders'} subheading={'Total Folders :'} card_data={"1"} />
              <SamllCard card_icon={iconFile} heading={'Files'} subheading={'Total Files :'} card_data={dashboardDetails && parseInt(dashboardDetails._filesCount._hex)} />
              <SamllCard card_icon={iconStorage} heading={'Storage Plan'} subheading={'Status :'}
                card_data={`${usedSpace && (usedSpace && usedSpace / 1000000).toFixed(2)} MB / ${currentPackage && currentPackage === "Starter" ? "1 TB" : ""} ${currentPackage && currentPackage === "Basic" ? "1 GB" : ""} ${currentPackage && currentPackage === "Advance" ? "5 TB" : ""}`} />
            </div>

            {/* netwoek section */}
            <div className='h-auto w-full'>
              <NetworkCard card_heading={"Network Usage"} card_image={iconWifi} Card_subheading={"Total Network Usage :"} />
            </div>

            {/* table content */}
            <div className='h-auto w-full'>
              <CardTable NameArr={fileNames} iconCard={tableUpload} iconFile={iconFile} heading={"Recent Uploads"} />
            </div>
          </div>

          {/* chart div with pie chart and files music and  other section */}
          <div className={`flex  p-1 h-auto lg:w-1/3 md:w-full sm:w-full min-[320px]:w-full ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "grayscale blur-sm"}`}>
            <PieChartCard card_icon={iconStatus} card_heading={'Status'} card_subHeading={"Chart Details :"} UsedValue={usedSpace && (usedSpace && usedSpace / 1000000).toFixed(3)} FilesCount={dashboardDetails && parseInt(dashboardDetails._filesCount._hex)}
              currentPackage={currentPackage} />
          </div>

        </div>
      </div>

      {/* meta mask not install warning */}
      {noMetamask &&
        <a href="https://metamask.io/download/" target='_blank' rel='noreferrer'><div className="fixed bottom-4 right-4 px-5 py-2 bg-black border-[1px] border-red-500/40 rounded-lg flex gap-2 items-center">

          <div className="p-2 rounded-full bg-black border-red-500 border-[1px] z-[100]">
            <img src={metamaskLogo} className="w-[20px]" alt="" />
          </div>
          <div className='flex flex-col'>
            <h3 className="font-semibold text-[18px] text-red-500 ">Meta mask is not Detected! Please Install Metamask</h3>
            <h3 className='text-[14px] text-white/50'>(Please Click this Box to Download Meta Mask)</h3>
          </div>



        </div>
        </a>
      }
    </div>

  )
}

export default DashboardHome
